<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.bizUserId" placeholder="商户系统用户标识" allowClear/>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.memberAlias" placeholder="会员别名" allowClear/>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.memberType" placeholder="会员类型" allowClear>
            <a-select-option :value="2">企业会员</a-select-option>
            <a-select-option :value="3">个人会员</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select style="width:120px" v-model:value="searchForm.isLock" placeholder="是否锁定" allowClear>
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select style="width:120px" v-model:value="searchForm.isWithdraw" placeholder="是否可提现" allowClear>
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.memberName" placeholder="用户名" allowClear/>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.phone" placeholder="电话" allowClear/>
        </a-form-item>
        <a-space>
          <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch" :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>
    </template>
    <template #toolbarLeft>
      <a-space>
        <a-button @click="onAddShow()">
          新增
        </a-button>
      </a-space>
    </template>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange" destroyOnClose>
      <template #operation="{ record }">
        <a @click="onEditShow(record)">详情</a>
      </template>
    </a-table>
    <a-modal :footer="null" v-if="cloudShow" v-model:visible="cloudShow" :title="cloudShowTitle" width="1200px"
      @cancel="onCancel" :maskClosable="false">
      <CloudFlashc :propsBizUserId="bizUserId" ref="_cloudFlash" @addSuccess="addSuccess"/>
    </a-modal>
  </HxTable>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import HxTable from '@/components/HxTable'
import { getMemberList } from '@/api/carrier/cloudFlash'
import CloudFlashc from '../../carrier/toolDetail/cloudFlashc'
export default {
  components: {
    HxTable,
    CloudFlashc
  },
  setup () {
    const _cloudFlash = ref()
    const state = reactive({
      cloudShow: false,
      loading: true,
      cloudShowTitle: '新增',
      bizUserId: null,
      listData: [],
      searchForm: {
        bizUserId: '',
        memberAlias: '',
        memberName: '',
        memberType: null,
        isWithdraw: null,
        isLock: null
      },
      mirrorSearchForm: {},
      columns: [
        {
          title: '会员别名',
          dataIndex: 'memberAlias',
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '商户系统用户标识',
          dataIndex: 'bizUserId',
          align: 'center'
        },
        {
          title: '会员类型',
          dataIndex: 'memberType.label',
          align: 'center',
          width: '8%'
        },
        {
          title: '用户名',
          dataIndex: 'memberName',
          align: 'center',
          width: '8%'
        },
        {
          title: '电话',
          dataIndex: 'phone',
          align: 'center',
          width: '8%'
        },
        {
          title: '是否锁定',
          dataIndex: 'isLock.label',
          align: 'center'
        },
        {
          title: '是否可提现',
          dataIndex: 'isWithdraw.label',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      getMemberList({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.pagination.current = res.data.current
        }
      }).catch(err => {
        console.log('分页错误   ' + err)
      }).finally(() => {
          state.loading = false
        })
    }
    const addSuccess = (e) => {
      if(e) onSearch()
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      onSearch()
    }
    // 打开
    const onAddShow = () => {
      state.bizUserId = null
      state.cloudShow = true
      state.cloudShowTitle = '新增会员'
      setTimeout(() => {
        _cloudFlash.value.loadData()
      }, 1)
    }
    // 查看会员详情
    const onEditShow = (e) => {
      state.cloudShow = true
      state.bizUserId = e.bizUserId
      setTimeout(() => {
        _cloudFlash.value.loadData()
        state.cloudShowTitle = '会员详情'
      }, 1)
    }
    //  关闭对话框
    const onCancel = () => {
      state.bizUserId = null
      state.cloudShow = false
      state.cloudShowTitle = ''
    }
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    return {
      ...toRefs(state),
      _cloudFlash,
      reset,
      addSuccess,
      onSearch,
      loadData,
      onAddShow,
      onEditShow,
      onCancel,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
